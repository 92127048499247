<template>
    <MainContainer>
        <div class="user">
            <h2>Benutzer*innen</h2>
            <b-row class="my-4">
                <b-col md="9">
                    <h5>Filter</h5>
                </b-col>
                <b-col md="3">
                    <h5>Funktionen</h5>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        :disabled="true"
                        @click="openModificationModal({ mode: 'create' })"
                        >Hinzufügen</b-button
                    >
                    <b-button
                        class="mx-1"
                        variant="outline-danger"
                        size="sm"
                        :disabled="!(selected.length > 0) || true"
                        @click="openDeleteModal({ multi: true })"
                        >Löschen</b-button
                    >
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" class="my-4">
                    <TableFilter v-model="filter.pattern" />
                </b-col>
            </b-row>
            <b-table
                :items="user"
                :fields="currentSelectedFields"
                :filter="filter.pattern"
                :busy="busy.data"
                :small="size == 'sm'"
                select-mode="multi"
                sort-by="lastname"
                ref="selectableTable"
                empty-text="Keine Daten vorhanden"
                empty-filtered-text="Für den gewählten Filter wurden keine Daten gefunden"
                foot-clone
                no-footer-sorting
                selectable
                bordered
                striped
                responsive
                show-empty
                @row-selected="onRowSelected"
            >
                <template #thead-top="{ columns }">
                    <b-tr>
                        <b-td>
                            <p class="small m-0">Gesamt: {{ user.length }}</p>
                            <p class="small m-0">
                                Gewählt:
                                <template v-if="selected.length > 0"
                                    >{{ selected.length }}
                                </template>
                                <template v-else>-</template>
                            </p>
                        </b-td>
                        <b-td :colspan="currentRecordFields.length"></b-td>
                        <b-td class="text-right"
                            ><b-dropdown
                                variant="outline-secondary"
                                text="Feldauswahl"
                                :size="size"
                            >
                                <b-dropdown-form>
                                    <b-form-checkbox-group
                                        v-model="selectedFields"
                                        :options="recordFields"
                                        value-field="key"
                                        text-field="label"
                                    ></b-form-checkbox-group>
                                </b-dropdown-form>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                </template>

                <template #table-busy>
                    <div class="text-center text-secondary my-2">
                        <b-spinner class="mr-1 align-middle" small></b-spinner>
                        <strong>Daten werden geladen...</strong>
                    </div>
                </template>

                <template #head(__selected)>
                    <input
                        type="checkbox"
                        v-model="selectAll"
                        :true-value="true"
                        :false-value="false"
                        @change="toggleAllSelectedRows"
                    />
                </template>

                <template #cell(__selected)="{ rowSelected }">
                    <input
                        type="checkbox"
                        :checked="rowSelected"
                        :true-value="true"
                        :false-value="false"
                        disabled
                    />
                </template>

                <template #cell(group_id)="row">
                    {{ groupName(row.value) }}
                </template>

                <template #cell(locations)="row">
                    <a
                        v-for="location_id in row.value"
                        :key="location_id"
                        href="#"
                        class="d-block"
                    >
                        {{ locationName(location_id) }}
                    </a>
                </template>

                <template #cell(lastInteraction)="row">
                    {{ formatDateTime(row.value) }}
                </template>

                <template #cell(__actions)="data">
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        :disabled="true"
                        @click="openEditModal(data.item)"
                        >Bearbeiten</b-button
                    >
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        :disabled="true"
                        @click="openDeleteModal(data.item)"
                        >Löschen</b-button
                    >
                </template>
            </b-table>

            <EditModal
                :busy="busy.mutation"
                :mode="modificationMode"
                @ok="handleModification"
            >
            </EditModal>

            <DeleteModal
                :mode="modificationMode"
                :record="modificationRecord"
                :fields="currentRecordFields"
                :selected="selected"
                :busy="busy.mutation"
                @ok="deleteRecord"
            >
            </DeleteModal>
        </div>
    </MainContainer>
</template>

<script>
import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import DateFormat from "@/mixins/Date/Date";

import MainContainer from "@/components/MainContainer/MainContainer";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import EditModal from "@/components/EditModal/EditModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "User",
    components: {
        MainContainer,
        TableExport,
        TableFilter,
        EditModal,
        DeleteModal,
        FormGroupBuilder
    },
    mixins: [ModelView, Size, DateFormat],
    data() {
        return {
            filter: {
                pattern: null
            },
            fields: [
                {
                    key: "__selected",
                    label: "",
                    class: "text-center",
                    static: true,
                    position: "prepend"
                },
                {
                    key: "id",
                    label: "ID",
                    sortable: true
                },
                {
                    key: "group_id",
                    label: "Gruppe",
                    sortable: true
                },
                {
                    key: "username",
                    label: "Nutzer*innenname",
                    sortable: true
                },
                {
                    key: "email",
                    label: "E-Mail",
                    sortable: true
                },
                {
                    key: "locations",
                    label: "Standorte"
                },
                {
                    key: "lastInteraction",
                    label: "Letzte Interaktion",
                    sortable: true
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            ioFields: {},
            selectedFields: [
                "id",
                "group_id",
                "username",
                "email",
                "locations",
                "lastInteraction"
            ]
        };
    },
    computed: {
        ...mapGetters({
            busy: "user/getBusy",
            user: "user/getUser",
            groupName: "userGroups/getUserGroupName",
            locationName: "locations/getName"
        }),
        dataAvailable() {
            return this.user.length > 0;
        }
    },
    created() {
        this.fetch({ filter: this.filter });
        this.fetchGroups();
        this.fetchLocations();
    },
    methods: {
        ...mapActions({
            fetch: "user/fetchUser",
            fetchGroups: "userGroups/fetchGroups",
            fetchLocations: "locations/fetchData"
        })
    }
};
</script>
